const uploadOccupancyCsv = async (formData, siteId) => {
  let result = null;
  try {
    result = await window.axios.post('/v1/sensor/sites/' + siteId + '/occupancy/monthly', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const addSite = async (formData) => {
  let result = null;
  try {
    result = await window.axios.post('/v1/sites/', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }  
};

const updateSite = async (formData) => {
  let result = null;
  try {
    result = await window.axios.put('/v1/sites/', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }  
}

const updateSiteImage = async (siteId, imgFile) => {
  let result = null;
  let formData = new FormData;
  formData.append("siteImg", imgFile, imgFile.name)
  let config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  try {
    result = await window.axios.post('/v1/sites/updateImage/'+siteId, formData, config);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }  
}

const getSiteById = async (siteId) => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/sites/${siteId}`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }  
}

const getSites = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/sites`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }  
}


// site create lookups
const getWeatherStations = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/sites/weatherstations');
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }    
}

const getTimezones = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/sites/timezones');
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }    
}

const getSiteTypes = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/sites/sitetypes');
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }    
}

const getUtilityCompanies = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/sites/utilitycompanies');
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }    
}

const getMarketContexts = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/sites/marketcontexts');
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }    
}

const getSiteStartStopTimes = async (siteId) => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/sites/opentimes/${siteId}`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }  
}

const getStates = () => {
  return [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]
}

const getSiteContactNotificationFrequencies = async() => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/sitecontactnotificationfrequency/`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }  
}

export default {
  uploadOccupancyCsv,
  addSite,
  updateSite,
  updateSiteImage,
  getSiteById,
  getSites,
  getWeatherStations,
  getTimezones,
  getUtilityCompanies,
  getSiteTypes,
  getMarketContexts,
  getStates,
  getSiteContactNotificationFrequencies,
  getSiteStartStopTimes
};