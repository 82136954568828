class fileUploadService {
  async uploadGas(file, path, meters, uploadTypeId) {
    let formData = new FormData;
    formData.append("uploadedFile", file, file.name)
    formData.append("meters", meters)
    let config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    try {
      debugger;
      let result = await window.axios.post(`${path}?uploadTypeId=${uploadTypeId}`, formData, config);
      return result.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
  }

  async uploadOccupancy(file, path, uploadTypeId) {
    let formData = new FormData;
    formData.append("uploadedFile", file, file.name)
  
    try {
      debugger;
      let result = await window.axios3.post(`${path}?uploadTypeId=${uploadTypeId}`, formData);
      return result;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
  }
}

export default new fileUploadService