const getMeters = async (siteId) => {
    let result = null
    try {
      result = await window.axios.get(`/v1/utilitymeter/${siteId}`)
      let list = result.data.data;
      return list;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
}

export default {
    getMeters
}