<template>
  <v-container grid-list-xl>
    <v-sheet elevation="4" class="pa-4 mt-2">
      <v-tabs right>
        <v-tab>Billing Data</v-tab>

        <v-tab-item>
          <v-card class="mt-4">
            <v-card-title class="title blue-grey lighten-4">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto" md="6" sm="6">
                  <v-autocomplete
                    :items="sites"
                    item-value="siteId"
                    item-text="name"
                    v-model="selectedSite"
                    return-object
                    label="Select site"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-autocomplete
                    :items="meters"
                    item-text="friendlyName"
                    item-value="id"
                    v-model="selectedMeter"
                    label="Select Meter"
                    return-object
                  ></v-autocomplete>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content color="rgba(0, 0, 0, 0.54) !important">
                  <v-row>
                    <v-col cols="auto" sm="3"
                      >Last Updated: {{ lastBillingUpdate }}</v-col
                    >
                    <v-col>
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="mt-n2"
                        v-if="loading"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-file-input
                small-chips
                label="Select Billing Data"
                v-model="billingFile"
                class="pr-4"
              ></v-file-input>
              <v-btn
                icon
                title="Upload Billing csv File"
                @click="uploadGas('billing')"
                :disabled="billingFile === null || loading"
              >
                <v-icon large class="pa-2">cloud_upload</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>

        <v-tab>Interval Data</v-tab>

        <v-tab-item>
          <v-card class="mt-4">
            <v-card-title class="title blue-grey lighten-4">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto" md="6" sm="6">
                  <v-autocomplete
                    :items="sites"
                    item-value="siteId"
                    item-text="name"
                    v-model="selectedSite"
                    return-object
                    label="Select site"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-autocomplete
                    :items="meters"
                    item-text="friendlyName"
                    item-value="id"
                    v-model="selectedMeter"
                    label="Select Meter"
                    return-object
                  ></v-autocomplete>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content color="rgba(0, 0, 0, 0.54) !important">
                  <v-row>
                    <v-col cols="auto" sm="3"
                      >Last Updated: {{ lastIntervalUpdate }}</v-col
                    >
                    <v-col>
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="mt-n2"
                        v-if="loading"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-file-input
                small-chips
                label="Select Interval Data"
                v-model="intervalFile"
                class="pr-4"
              ></v-file-input>
              <v-btn
                icon
                title="Upload Interval csv File"
                @click="uploadGas('interval')"
                :disabled="intervalFile === null || loading"
              >
                <v-icon large class="pa-2">cloud_upload</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab>Wide Format Data</v-tab>

        <v-tab-item>
          <v-card class="mt-4">
            <v-card-title class="title blue-grey lighten-4">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto" md="6" sm="6">
                  <v-autocomplete
                    :items="sites"
                    item-value="siteId"
                    item-text="name"
                    v-model="selectedSite"
                    return-object
                    label="Select site"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-autocomplete
                    :items="meters"
                    item-text="friendlyName"
                    item-value="id"
                    v-model="selectedMeter"
                    label="Select Meter"
                    return-object
                  ></v-autocomplete>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content color="rgba(0, 0, 0, 0.54) !important">
                  <v-row>
                    <v-col cols="auto" sm="3"
                      >Last Updated: {{ lastWideIntervalUpdate }}</v-col
                    >
                    <v-col>
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="mt-n2"
                        v-if="loading"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-file-input
                small-chips
                label="Select Wide Interval Data"
                v-model="wideIntervalFile"
                class="pr-4"
              ></v-file-input>
              <v-btn
                icon
                title="Upload Wide Interval csv File"
                @click="uploadGas('wideInterval')"
                :disabled="wideIntervalFile === null || loading"
              >
                <v-icon large class="pa-2">cloud_upload</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>

        <v-tab>Occupancy</v-tab>

        <v-tab-item>
          <v-card class="mt-4">
            <v-card-title class="title blue-grey lighten-4">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto" md="6" sm="6">
                  <v-autocomplete
                    :items="sites"
                    item-value="siteId"
                    item-text="name"
                    v-model="selectedSite"
                    return-object
                    label="Select site"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-list>
              <v-list-item>
                <v-list-item-content color="rgba(0, 0, 0, 0.54) !important">
                  <v-row>
                    <v-col cols="auto" sm="3"
                      >Last Updated: {{ lastOccupancyUpdate }}</v-col
                    >
                    <v-col>
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="mt-n2"
                        v-if="loading"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-file-input
                small-chips
                label="Select Occupancy Data"
                v-model="occupancyFile"
                class="pr-4"
              ></v-file-input>
              <v-btn
                icon
                title="Upload Occupancy csv File"
                @click="uploadOccupancy('monthly')"
                :disabled="occupancyFile === null || loading"
              >
                <v-icon large class="pa-2">cloud_upload</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-sheet>
  </v-container>
</template>

<script>
import uploadService from "../services/fileuploadservice";
import gasapi from "../_api";
import api from "../../site/_api";
export default {
  name: "GasUpload",

  props: {},

  data() {
    return {
      loading: false,
      sites: [],
      meters: [],
      selectedSite: null,
      selectedSiteId: null,
      siteSelected: null,
      selectedMeterId: null,
      lastBillingUpdate: "2020-06-01 11:20:58",
      billingFile: null,
      lastIntervalUpdate: "none",
      intervalFile: null,
      selectedIntervalMeter: {},
      lastWideIntervalUpdate: "none",
      wideIntervalFile: null,
      selectedWideIntervalMeters: [],
      selectedIntervalMeters: [],
      occupancyFile: null,
      lastOccupancyUpdate: "none",
      OccupancyFile: null,
    };
  },

  async created() {
    const self = this;
    const siteId = this.$route.params.siteId;
    const meterId = this.$route.params.meterId;

    this.sites = await api.getSites();
    this.selectedSiteId = siteId;
    this.selectedSite = this.sites.find((s) => s.siteId == siteId);

    this.meters = await gasapi.getMeters(this.selectedSiteId);
    this.selectedMeter = this.meters.find((m) => m.id == meterId);

    console.log(self.selectedSiteId);
    console.log(this.selectedMeterId);
    console.log(this.sites);
    console.log(this.meters);
    console.log(this.selectedSite);
    console.log(this.selectedMeter);
  },

  // watch: {
  //   selectedSiteId: function (siteId) {
  //     this.getMeters(siteId);
  //   },
  // },

  methods: {
    async uploadGas(type) {
      this.loading = true;
      const basePath = `v1/gasreadings/sites/${this.selectedSite.siteId}/meter/${this.selectedMeter.id}`;
      let path = "";
      let file = "";
      let meters = [];
      let uploadType = "";
      switch (type) {
        case "billing":
          path = `${basePath}/billing`;
          file = this.billingFile;
          uploadType = 1;
          break;
        case "interval":
          path = `${basePath}/interval`;
          file = this.intervalFile;
          uploadType = 4;
          break;
        case "wideInterval":
          path = `${basePath}/interval/wide`;
          file = this.wideIntervalFile;
          uploadType = 2;
          break;

        default:
          break;
      }

      try {
        let result = await uploadService.uploadGas(
          file,
          path,
          meters,
          uploadType
        );
        this.$toast.show(result.data, null, "success");
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async uploadOccupancy(type) {
      this.loading = true;
      const basePath = `v1/sensor/sites/${this.selectedSite.siteId}`;
      let path = "";
      let file = "";
      let meters = [];
      let uploadType = "";
      switch (type) {
        case "monthly":
          path = `${basePath}/monthly`;
          file = this.occupancyFile;
          uploadType = 3;
          break;
        default:
          break;
      }

      try {
        await uploadService.uploadOccupancy(file, path, meters, uploadType);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getSites() {
      try {
        let response = await api.getSites();
        this.sites = response;
      } catch (error) {
        console.error(error);
        //this.server_errors = this.errorSummary(error);
      }
    },

    async getMeters(siteId) {
      try {
        let response = await gasapi.getMeters(siteId);
        this.meters = response;
      } catch (error) {
        console.error(error);
        //this.server_errors = this.errorSummary(error);
      }
    },
  },
};
</script>